<template>
  <div v-if="mode === 'edit'" class="lpr-setting">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <div class="config">
        <SwitchSetter v-model="paramSrc" :data="configLpr.paramSource" :enabled="!isAiCam" />
        <SwitchSetter v-model="aiModelType" :data="configLpr.aiModelType" :enabled="canSetting" />
        <div class="line"></div>
        <span v-if="isAiCam">{{ $t('event')/*事件*/ }}</span>
        <SwitchSetter v-model="setting.deviceConfig[lprModelType].eventPostMode" :data="configLpr.eventPostMode" :enabled="canSetting" />
        <SelectSetter v-model="notifyFilterMode" :data="configLpr.notifyFilterMode" :enabled="canSetting" />
        <SliderSetter v-model="setting.deviceConfig[lprModelType].eventDuration" :data="configLpr.eventDuration" :enabled="canSetting" />
        <SliderSetter v-if="isAiCam" v-model="setting.deviceConfig.fr.repostDataStoreLimit" :data="configLpr.repostDataStoreLimit" :enabled="canSetting" />
        <SliderSetter v-else v-model="setting.deviceConfig[lprModelType].repostDataStoreLimit" :data="configLpr.repostDataStoreLimit" :enabled="canSetting" />
        <template v-if="isAiCam">
          <div class="line"></div>
          <span>{{ $t('decibel_meter')/*分貝計*/ }}</span>
          <SliderSetter v-model="setting.deviceConfig[lprModelType].noiseCarDecibelThreshold" :data="configLpr.noiseCarDecibelThreshold" :enabled="canSetting" />
          <SliderSetter v-model="setting.deviceConfig[lprModelType].noiseCarEventDuration" :data="configLpr.noiseCarEventDuration" :enabled="canSetting" />
          <SliderSetter v-model="setting.deviceConfig[lprModelType].decibelOffset" :data="configLpr.decibelOffset" :enabled="canSetting" />
        </template>  
      </div>
      <div class="cam">
        <div class="tabs">
          <div 
            class="tab" 
            :class="{selected: index === camIndex}" 
            v-for="(n, index) in setting.channel.length" 
            :key="index"
            @click="switchCam(index)">
            CH{{ n }}
          </div>
        </div>
        <div class="tab-content">
          <SwitchSetter v-model="setting.channel[camIndex][lprModelType].isEnabled" :data="configLpr.isEnabled" :enabled="isAiCam" />
          <SliderSetter v-if="!isAiCam" v-model="setting.channel[camIndex][lprModelType].lprFrequency" :data="configLpr.lprFrequency" :enabled="canSetting" />
          <SliderSetter v-model="setting.channel[camIndex][lprModelType].lprSizeRatio" :data="configLpr.lprSizeRatio" :enabled="canSetting" />
          <div v-if="mode === 'edit'" class="wrap-edit-roi" :class="{ disabled: currDefaultSetting === 1 }">
            <div v-if="!isEditRoi" class="edit-roi" @click="editRoi">
              <img src="@/assets/icons/Crop.svg" alt="">
              <span>{{ $t('ai_roi_set')/*調整辨識範圍*/ }}</span>
            </div>
            <div v-else class="edit-roi" @click="lockRoi">
              <img src="@/assets/icons/lock.svg" alt="">
              <span>{{ $t('ai_roi_lock')/*鎖定辨識範圍*/ }}</span>
            </div>
            <div v-if="isEditRoi" class="reset-roi" @click="resetRoi">
              <img src="@/assets/icons/refresh-white.svg" alt="">
            </div>
          </div>
          <div class="expert-mode" @click="showAdvSettings = !showAdvSettings">
            <img v-if="showAdvSettings" src="@/assets/icons/Arrow_down.svg" alt="">
            <img v-else class="arrow-right" src="@/assets/icons/Arrow_down.svg" alt="">
            <span>{{ $t('ai_advanced_settings')/*進階設定*/ }}</span>
          </div>
          <template v-if="showAdvSettings">
            <SwitchSetter v-model="setting.channel[camIndex][lprModelType].detectMode" :data="configLpr.detectMode" :enabled="canSetting" />
            <SliderSetter v-model="setting.channel[camIndex][lprModelType].confirmCount" :data="configLpr.confirmCount" :enabled="canSetting" />
            <SliderSetter v-model="setting.channel[camIndex][lprModelType].acceptDiffNum" :data="configLpr.acceptDiffNum" :enabled="canSetting" />
            <SliderSetter v-model="setting.channel[camIndex][lprModelType].indent" :data="configLpr.indent" :enabled="canSetting" />
            <SwitchSetter v-if="aiModelType === 'jp'" v-model="setting.channel[camIndex][lprModelType].isCompleteMode" :data="configLpr.isCompleteMode" :enabled="canSetting" />
          </template>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="lpr-setting">
    <div class="title">
      <img src="@/assets/icons/recognition-yellow.svg" alt="">
      <span>{{ $t('ai_recognition_setting')/*辨識設定*/ }}</span>
    </div>
    <div class="content">
      <div class="config">
        <ConfigItem v-model="paramSrc" :data="configLpr.paramSource" :showTooltip="false" />
        <ConfigItem v-model="setting.aiModelType" :data="configLpr.aiModelType" :showTooltip="false" />
        <div class="line"></div>
        <span v-if="isAiCam">{{ $t('event')/*事件*/ }}</span>
        <ConfigItem v-model="setting.deviceConfig[lprModelType].eventPostMode" :data="configLpr.eventPostMode" :showTooltip="false" />
        <ConfigItem v-model="notifyFilterMode" :data="configLpr.notifyFilterMode" />
        <ConfigItem v-model="setting.deviceConfig[lprModelType].eventDuration" :data="configLpr.eventDuration" />
        <ConfigItem v-if="isAiCam" v-model="setting.deviceConfig.fr.repostDataStoreLimit" :data="configLpr.repostDataStoreLimit" />
        <ConfigItem v-else v-model="setting.deviceConfig[lprModelType].repostDataStoreLimit" :data="configLpr.repostDataStoreLimit" />
        <template v-if="isAiCam">
          <div class="line"></div>
          <span>{{ $t('decibel_meter')/*分貝計*/ }}</span>
          <ConfigItem v-model="setting.deviceConfig[lprModelType].noiseCarDecibelThreshold" :data="configLpr.noiseCarDecibelThreshold" />
          <ConfigItem v-model="setting.deviceConfig[lprModelType].noiseCarEventDuration" :data="configLpr.noiseCarEventDuration" />
          <ConfigItem v-model="setting.deviceConfig[lprModelType].decibelOffset" :data="configLpr.decibelOffset" />
        </template> 
        <div class="line"></div>
        <div class="time">
          <span>{{ $t('ai_set_time')/*設定時間*/ }}</span>
          <span>{{ formatTime(currentSetting.updateTime) }}</span>
        </div>
      </div>
      <div class="cam">
        <div class="tabs">
          <div 
            class="tab" 
            :class="{selected: index === camIndex}" 
            v-for="(n, index) in setting.channel.length" 
            :key="n"
            @click="switchCam(index)">
            CH{{ n }}
          </div>
        </div>
        <div class="tab-content">
          <ConfigItem v-model="setting.channel[camIndex][lprModelType].isEnabled" :data="configLpr.isEnabled" />
          <ConfigItem v-if="!isAiCam" v-model="setting.channel[camIndex][lprModelType].lprFrequency" :data="configLpr.lprFrequency" />
          <ConfigItem v-model="setting.channel[camIndex][lprModelType].lprSizeRatio" :data="configLpr.lprSizeRatio" />
          <ConfigItem v-model="setting.channel[camIndex][lprModelType].detectMode" :data="configLpr.detectMode" />
          <ConfigItem v-model="setting.channel[camIndex][lprModelType].confirmCount" :data="configLpr.confirmCount" />
          <ConfigItem v-model="setting.channel[camIndex][lprModelType].acceptDiffNum" :data="configLpr.acceptDiffNum" />
          <ConfigItem v-model="setting.channel[camIndex][lprModelType].indent" :data="configLpr.indent" />
          <ConfigItem v-if="aiModelType === 'jp'" v-model="setting.channel[camIndex][lprModelType].isCompleteMode" :data="configLpr.isCompleteMode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { configLpr } from '@/config/configLpr'
import moment from 'moment'

export default {
  name: 'LprSetting',
  components: {
    SwitchSetter: () => import('@/components/AiBox/base/SwitchSetter.vue'),
    SliderSetter: () => import('@/components/AiBox/base/SliderSetter.vue'),
    SelectSetter: () => import('@/components/AiBox/base/SelectSetter.vue'),
    ConfigItem: () => import('@/components/AiBox/base/ConfigItem.vue'),
  },
  data() {
    return {
      configLpr,
      globalConfig: {},
      userConfig: {},
      showAdvSettings: false,
      isEditExpertMode: false,
      isViewExpertMode: false,
    }
  },
  computed: {
    ...mapGetters(['timezone']),
    ...mapState('aiboxLpr', ['mode', 'currDefaultSetting', 'defaultSetting', 
      'camIndex', 'isEditRoi', 'userSetting', 'faceSizeRatio', 'lprNotifyFilterMode']),
    ...mapGetters('aiboxLpr', ['isAiCam', 'currentSetting', 'deviceModelId']),
    paramSrc: {
      get() {
        return this.currDefaultSetting
      },
      set(val) {
        this.onUpdateCurrDefaultSetting(val)
      },
    },
    aiModelType: {
      get() {
        return this.currentSetting.setting.aiModelType
      },
      set(val) {
        this.updateAiModelType(val)
      },
    },
    lprModelType() {
      // lprTw, lprJp
      return 'lpr' + this.aiModelType.slice(0, 1).toUpperCase() + this.aiModelType.slice(1)
    },
    canSetting() {
      // defaultSetting = 0: userSetting 設備客製/可編輯, 1: defaultSetting 系統預設/不可編輯
      return this.currDefaultSetting === 0 && !this.isEditRoi
    },
    setting: {
      get() {
        return this.currentSetting.setting
      },
      set(data) {
        this.updateSetting(data)
      },
    },
    notifyFilterMode: {
      get() {
        return this.lprNotifyFilterMode
      },
      set(val) {
        this.updateLprNotifyFilterMode(val)
      },
    },
    config() {
      return this.currDefaultSetting === 1 ? this.globalConfig : this.userConfig
    },
  },
  watch: {
    scrollFaceRatio(val) {
      this.updateFaceSizeRatio(val)
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('aiboxLpr', ['updateSetting', 'updateAiModelType', 
      'updateIsEditRoi', 'updateCamIndex', 'updateROI', 'updateLprNotifyFilterMode']),
    ...mapActions('aiboxLpr', ['onUpdateCurrDefaultSetting']),
    formatTime(dateTime) {
      return moment(dateTime).tz(this.timezone).format('YYYY-MM-DD HH:mm:ss')
    },
    editRoi() {
      this.updateIsEditRoi(true)
    },
    lockRoi() {
      this.updateIsEditRoi(false)
    },
    resetRoi() {
      this.updateROI({
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      })
    },
    switchCam(index) {
      this.updateCamIndex(index)
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.lpr-setting {
  width: 100%;
  height: 100%;

  .title {
    display: flex;
    align-items: center;
    font-size: px2rem(24);
    line-height: 36px;
    font-weight: 500;
    color: #FFE99F;
    margin-bottom: 8px;
    img {
      margin-right: 8px;
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    height: calc(100% - 46px);
    box-sizing: border-box;
    position: relative;
    .config {
      height: 35%;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      padding: 12px 4px 12px 12px;
      background: #4A5C7833;
      border-radius: 8px;
      overflow: overlay;

      span {
        font-size: px2rem(24);
        color: #FFE99F;
      }
    }

    .cam {
      height: 65%;
      border-radius: 8px;
      .tabs {
        display: flex;

        .tab {
          color: #ffffff;
          font-size: 20px;
          line-height: 28px;
          padding: 7px 16px;
          background: #4A5C78;
          opacity: 0.5;
          cursor: pointer;
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
          &.selected {
            background: #4A5C78;
            opacity: 1;
          }
        }
      }

      .tab-content {
        height: calc(100% - 64px);
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        padding: 12px 4px 12px 12px;
        background: #4A5C7833;
        // border-radius: 0 8px 8px 8px; --> 這行導致內容模糊！！！
        overflow-y: scroll;
        
      }
    }

    .line {
      width: 100%;
      height: 1px;
      border-bottom: 1px solid #ffffff80;
    }
    .expert-mode {
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 28px;
      color: #ffffff;
      cursor: pointer;
      img {
        margin-right: 12px;
        &.arrow-right {
          transform: rotate(-90deg);
        }
      }
    }
    .mode {
      color:#ffffff80;
    }

    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #ffffff80;
        font-size: px2rem(20);
      }
    }
  }
}

.wrap-edit-roi {
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 12px;
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}
.edit-roi {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  padding: 4px 12px;
  background: #4A5C78;
  border-radius: 8px;
  cursor: pointer;
  
  img {
    margin-right: 8px;
  }
}
.reset-roi {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  background: #4A5C7880;
  cursor: pointer;
  &:hover {
    background: #4A5C78;
  }
}
</style>